import { message, notification } from 'antd';
import axios from 'axios';
import React, { createContext, useEffect, useLayoutEffect, useReducer } from 'react';
import useQuery from '../../hooks/useQuery.hook';
import { useNavigate } from 'react-router-dom';
import { Checkout, EligibilityStatus, Eligible, ErrorTypeEnum, OrderStatus } from '../../types/common/response.type';
import routeMap from '../../routes/map';
import { convertObjectToQueryParams, isMobile } from '../../utils';
import useCheckoutSvc from '../../services/checkout.svc';
import { AppAction, AppReducer, AppState, initialState } from './reducer';
import Loader from '../../components/Loader';
import { HeaderDetails, Plan, PlanConfig } from '../../types/plan.type';
import usePlanSvc from '../../services/plan.svc';
import { CloseCircleTwoTone } from '@ant-design/icons';
import useEligibilitySvc from '../../services/eligibility.svc';

interface AppContextType {
    state: AppState;
    handleError: (err: unknown) => void;
    handleCheckout: (checkout: Checkout, params?: Record<string, string>) => void;
    updateUserToken: (token?: string) => void;
    updatePlanConfig: (planConfig: Partial<PlanConfig>) => void;
    isEligibilityFlow: boolean;
    handleEligibility: (eligibilityState: Eligible) => void;
    moveNext: () => Promise<void>;
    getUserPlan: () => void;
    openNotification: (message: string) => void;
    headerDetails: HeaderDetails;
}

export const AppContext = createContext<AppContextType>({} as AppContextType);

export const AppStateProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    const [api, contextHolder] = notification.useNotification();
    const openNotification = (message: string) => {
        api.info({
            message: ``,
            description: <div>{message}</div>,
            placement: isMobile() ? 'bottom' : 'top',
            type: 'error',
            icon: <CloseCircleTwoTone twoToneColor={'red'} />,
        });
    };
    const { queryParamsString, queryParams } = useQuery();
    const { getCart, getCheckoutState } = useCheckoutSvc();
    const { getPublicPlans, getPlans, getHeaderDetails } = usePlanSvc();
    const navigate = useNavigate();
    const { checkEligibility } = useEligibilitySvc();
    const { planConfig, cart, loading, headerDetails } = state;
    const { planId, flowType, redirectUrl, id, poll } = queryParams;
    const isEligibilityFlow =
        window.location.pathname.startsWith('/eligibility') ||
        redirectUrl?.startsWith('/eligibility') ||
        flowType === 'eligibility';
    const { UPDATE_CART, UPDATE_LOADING, UPDATE_USER, UPDATE_PLAN_CONFIG, UPDATE_HEADER_DETAILS } = AppAction;
    const { COMPLETED } = OrderStatus;

    const handleCheckout = (checkoutState: Checkout, extraParams?: Record<string, string>) => {
        const { errorType, required: path, message, orderStatus } = checkoutState;
        const { REJECTION, REQUIRED, VALIDATION } = ErrorTypeEnum;
        if (orderStatus === COMPLETED) {
            const params = convertObjectToQueryParams({
                ...queryParams,
                cid: checkoutState.id,
                poll: false,
            });
            navigate(`/order/status?${params}`, { replace: true });
            return;
        }
        switch (errorType) {
            case REQUIRED:
                const params = convertObjectToQueryParams({
                    ...queryParams,
                    cid: checkoutState.id,
                    poll: false,
                    ...(extraParams && extraParams),
                });
                navigate(`${routeMap[path]}?${params}`, { state: { fields: checkoutState.requiredFields } });
                break;
            case VALIDATION:
                if (message) {
                    openNotification(message);
                }
                break;
            case REJECTION:
                navigate('/failure', {
                    state: {
                        message,
                    },
                    replace: true,
                });
                break;
        }
    };

    const handleEligibility = (eligibilityState: Eligible) => {
        const { message, status } = eligibilityState;
        const { KYC_REQUIRED, PAN_REQUIRED, APPROVED, REJECTED } = EligibilityStatus;
        const params = convertObjectToQueryParams({
            ...queryParams,
            poll: false,
        });

        switch (status) {
            case KYC_REQUIRED:
                navigate(`/eligibility/kyc?${params}`, { state: { fields: eligibilityState.requiredFields } });
                break;
            case PAN_REQUIRED:
                navigate(`/eligibility/profile?${params}`, { state: { fields: eligibilityState.requiredFields } });
                break;
            case APPROVED:
                navigate(`/eligibility/limits?${params}`, { replace: true });
                break;
            case REJECTED:
                navigate(`/failure`, { replace: true, state: { message } });
        }
    };

    const handleError = (err: unknown) => {
        if (axios.isAxiosError<{ code: string; message?: string }>(err)) {
            const { code = '', message = '' } = err.response?.data || {};
            const errorMsg: string = message || code;
            switch (err.response?.status) {
                case 403:
                    updateUserToken();
                    navigate(`/otp?${queryParamsString}&redirectUrl=${window.location.pathname}`, {
                        replace: true,
                    });
                    break;
                case 412:
                    navigate(`/failure?${queryParamsString}`, {
                        state: {
                            message: errorMsg,
                        },
                    });
                    break;
                case 400:
                case 404:
                    openNotification(errorMsg);
                    break;
                default:
                    navigate(`/error?${queryParamsString}`, {
                        state: {
                            message: errorMsg,
                        },
                    });
                    break;
            }
        } else if (err instanceof Error) {
            navigate(`/error?${queryParamsString}`, {
                state: {
                    message: err.message,
                    response: err.cause,
                },
            });
        }
    };

    const updateLoading = (loading: boolean) => {
        dispatch({
            type: UPDATE_LOADING,
            payload: { loading },
        });
    };

    const updateCartDetails = async () => {
        try {
            updateLoading(true);
            const cart = await getCart();
            dispatch({
                type: UPDATE_CART,
                payload: { cart },
            });
            if (id && !poll) {
                await moveNext();
            }
        } catch (error) {
            navigate(`/failure?${queryParamsString}`);
        } finally {
            updateLoading(false);
        }
    };

    const updateUserToken = (token: string = '') => {
        localStorage.setItem('tk', token);
        dispatch({
            type: UPDATE_USER,
            payload: { user: { token } },
        });
    };

    const updatePlanConfig = (planConfig: Partial<PlanConfig>) => {
        dispatch({
            type: UPDATE_PLAN_CONFIG,
            payload: {
                planConfig: {
                    ...state.planConfig,
                    ...planConfig,
                },
            },
        });
    };

    const updateHeaderDetails = (headerDetails: Partial<HeaderDetails>) => {
        dispatch({
            type: UPDATE_HEADER_DETAILS,
            payload: {
                headerDetails: {
                    ...state.headerDetails,
                    ...headerDetails,
                },
            },
        });
    };

    const fetchPublicPlan = async () => {
        try {
            updatePlanConfig({ loading: true });
            const planRes = await getPublicPlans(cart.orderValue);
            const selectedPlan = planRes[0];
            updatePlanConfig({ plans: planRes, selectedPlan, planType: 'public', loading: false });
        } catch (err) {
            handleError(err);
        }
    };

    const fetchHeaderDetails = async () => {
        try {
            updateHeaderDetails({ loading: true });
            const headerDetails = await getHeaderDetails();
            updateHeaderDetails({ logoUrl: headerDetails?.logo, loading: false, title: headerDetails?.title });
        } catch (err) {
            updateHeaderDetails({ loading: false });
        }
    };

    const getUserPlan = async () => {
        try {
            updatePlanConfig({ loading: true });
            const planRes = await getPlans();
            if (!Array.isArray(planRes)) {
                updatePlanConfig({ loading: false });
                handleCheckout(planRes);
                return;
            }
            const selectedPlan = planRes?.find((p) => p.planId === (planId ?? planRes[0]?.planId));
            updatePlanConfig({ plans: planRes, selectedPlan, planType: 'user', loading: false });
        } catch (err) {
            handleError(err);
        }
    };

    const moveNext = async () => {
        try {
            if (isEligibilityFlow) {
                const eligibility = await checkEligibility();
                handleEligibility(eligibility);
            } else {
                const checkout = await getCheckoutState();
                handleCheckout(checkout);
            }
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        if (!headerDetails.logoUrl) {
            fetchHeaderDetails();
        }
        if (!cart.orderValue || window.location.pathname === '/plan') {
            return;
        }
        if (planId) {
            getUserPlan();
        } else {
            fetchPublicPlan();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.orderValue]);

    useLayoutEffect(() => {
        if (isEligibilityFlow) {
            updateLoading(false);
            return;
        }
        updateCartDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppContext.Provider
            value={{
                state,
                handleError,
                handleCheckout,
                updateUserToken,
                updatePlanConfig,
                getUserPlan,
                isEligibilityFlow,
                handleEligibility,
                moveNext,
                openNotification,
                headerDetails,
            }}
        >
            {loading ? <Loader /> : children}
            {contextHolder}
        </AppContext.Provider>
    );
};
