import useRestClient from '../hooks/restClient.hook';
import useQuery from '../hooks/useQuery.hook';
import { Checkout } from '../types/common/response.type';
import { Plan } from '../types/plan.type';
import { convertObjectToQueryParams } from '../utils';

const usePlanSvc = () => {
    const restClient = useRestClient();
    const { queryParams } = useQuery();
    const { id, merchantId: mid } = queryParams;

    async function getPlans(): Promise<Array<Plan> | Checkout> {
        return restClient.get(`/plans?id=${id}`);
    }

    async function selectPlan(plan: Plan): Promise<Checkout> {
        return restClient.post(`/plans?id=${id}`, plan);
    }

    async function getPublicPlans(orderValue: number): Promise<Array<Plan>> {
        const params = convertObjectToQueryParams({ mid, orderValue });
        return restClient.get(`/pub/plans?${params}`);
    }

    async function getEligibilityPlans(orderValue: number): Promise<Array<Plan>> {
        const params = convertObjectToQueryParams({
            mid,
            orderValue,
        });
        return restClient.get(`/eligible/plans?${params}`);
    }

    async function getHeaderDetails(): Promise<{ logo: string; title: string }> {
        return restClient.get(`/pub/merchant/${mid}/header-details`);
    }

    return {
        getPlans,
        selectPlan,
        getPublicPlans,
        getEligibilityPlans,
        getHeaderDetails,
    };
};

export default usePlanSvc;
