import { useContext } from 'react';
import useQuery from '../../hooks/useQuery.hook';
import { AppContext } from '../../context/app';
import Shimmer from '../Shimmer';

export default function Header() {
    const { queryParams } = useQuery();
    const { state } = useContext(AppContext);
    const { headerDetails } = state;
    const routeHeaderMap: Record<string, string> = {
        '/login': 'Enter Mobile Number',
        '/otp': 'Verify Number',
        '/address':
            queryParams.addressType === 'new_address'
                ? 'Add Delivery Address'
                : queryParams.addressType === 'address_list'
                ? 'Choose Delivery Address'
                : '',
        '/profile': 'Personal Details',
        '/income_verify': 'Bank Details',
        '/plan': 'Choose Payment Plan',
        '/kyc': 'Complete KYC',
        '/auto-debit': 'Set Up EMI Auto Pay',
        '/dp': 'Select Payment Method',
        '/eligibility/profile': 'Personal Details',
        '/eligibility/limits': 'Credit Approved',
        '/order/status': 'Order Processing',
    };
    return (
        <div className="bg-header flex items-center min-[600px]:justify-center">
            {headerDetails.loading ? (
                <div className="w-full h-[48px]">
                    <Shimmer className="h-[48px]" />
                </div>
            ) : (
                <>
                    {headerDetails.logoUrl ? (
                        <>
                            <div className="ml-[16px] w-[36px] h-[36px] flex items-center justify-center rounded-sm border border-solid border-pie">
                                <img src={headerDetails.logoUrl} alt="Header Logo" width={22} height={16} />
                            </div>
                            <p className="ml-[16px] text-primaryGray font-openSansSemiBold text-18 py-[12px]">
                                {routeHeaderMap[window.location.pathname] || headerDetails.title}
                            </p>
                        </>
                    ) : (
                        <p className="ml-[16px] text-primaryGray font-openSansSemiBold text-18 py-[12px]">
                            {routeHeaderMap[window.location.pathname] || (
                                <img src="/images/snapmint.svg" alt="Default Header" />
                            )}
                        </p>
                    )}
                </>
            )}
        </div>
    );
}
